import AppModule from './modules/AppModule';
import LoginModule from './modules/LoginModule';
import FeedbackModule from './modules/FeedbackModule';
import RegisterModule from './modules/RegisterModule';
import ProfileModule from './modules/ProfileModule';
import PolicyModule from './modules/PolicyModule';
import LoadingModule from './modules/LoadingModule';

const store = {};

export default function initializeStore(initialData = {
    app: {},
    login: {},
    register: {},
    feedback: {},
    profile: {},
    policy: {},
    loading: {},
}) {
    store.app = new AppModule(initialData.app);
    store.login = new LoginModule(initialData.login, store.app);
    store.feedback = new FeedbackModule(initialData.feedback, store.app);
    store.profile = new ProfileModule(initialData.profile, store.app);
    store.register = new RegisterModule(initialData.register, store.app, store.feedback, store.profile);
    store.policy = new PolicyModule(initialData.policy, store.app);
    store.loading = new LoadingModule();

    return store;
}
