import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import DetailsContentWrapper from 'components/_modules/wrappers/DetailsContentWrapper/index.js';
import TitledContent from 'components/TitledContent';
import TextContent from 'components/TextContent';
import TaxDeductionGeneratingCertificateBlock from 'components/_modules/policy/TaxDeductionGeneratingCertificateBlock';
import TaxDeductionGeneratedCertificatesList from 'components/_modules/policy/TaxDeductionGeneratedCertificatesList';
import Preloader from 'components/Preloader/Preloader';
import { GET_SOCIAL_TAX_DEDUCTION_SELECTIONS, GET_SOCIAL_TAX_DEDUCTION_CERTIFICATE_LIST } from 'api';
import styles from './styles.module.scss';

const SocialTaxDeductionTab = ({ items, policyId, store }) => {
    const [loading, setLoading] = useState(true);
    const [selectionsData, setSelectionsData] = useState(null);
    const [certificatesList, setCertificatesList] = useState(null);

    useEffect(() => {
        const getData = () => {
            GET_SOCIAL_TAX_DEDUCTION_SELECTIONS(policyId).then(({ result }) => {
                setSelectionsData(result);
            }).then(() => {
                GET_SOCIAL_TAX_DEDUCTION_CERTIFICATE_LIST(policyId).then(({ result }) => {
                    setCertificatesList(result);
                }).then(() => {
                    setLoading(false);
                });
            });
        };

        if (selectionsData === null || certificatesList === null) {
            getData();
        } else {
            setLoading(false);
        }
    }, [policyId, selectionsData, certificatesList]);

    return (
        loading
            ? <DetailsContentWrapper>
                <div className={styles.PreloaderWrapper}>
                    <Preloader embedded/>
                </div>
            </DetailsContentWrapper>
            : <React.Fragment>
                {items.map((item, itemIndex) => (
                    <React.Fragment key={itemIndex}>
                        {(item.type === 'tabHeader' && (
                            <DetailsContentWrapper
                                padding="55px 0 75px"
                                backgroundColor="#F8F8FA"
                            >
                                <div className={styles.TitledContentWrapper}>
                                    {item.values.map((value, valueIndex) => (
                                        <TitledContent
                                            key={valueIndex}
                                            title={value.title}
                                            content={value.subTitle}/>
                                    ))}
                                </div>
                            </DetailsContentWrapper>))}
                        {item.type === 'content' && (
                            <DetailsContentWrapper title={item.title}>
                                {item.values.map((value, valueIndex) => (
                                    <TextContent key={valueIndex}
                                        dangerouslySetInnerHTML={{ __html: value.value }}/>
                                ))}
                            </DetailsContentWrapper>
                        )}
                        {item.type === 'tax_deduction_generating_certificate_block' && (
                            <DetailsContentWrapper title={item.title}>
                                <TaxDeductionGeneratingCertificateBlock
                                    store={store}
                                    policyId={policyId}
                                    policyNumber={store.policy.policy.policyNumber}
                                    selections={selectionsData}
                                />
                            </DetailsContentWrapper>
                        )}
                        {item.type === 'tax_deduction_generated_certificates_list' && certificatesList.length > 0 && (
                            <DetailsContentWrapper title={item.title}>
                                <TaxDeductionGeneratedCertificatesList
                                    certificates={certificatesList}
                                />
                            </DetailsContentWrapper>
                        )}
                    </React.Fragment>
                ))}
            </React.Fragment>
    );
};

export default inject('store')(observer(SocialTaxDeductionTab));
